<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <div>
            <label for="example-datepicker">Choose Start Date</label>
            <b-form-datepicker
              id="start-datepicker"
              v-model="value"
              class="mb-1"
            />
            <p>Value: '{{ value }}'</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <label for="example-datepicker">Choose End Date</label>
            <b-form-datepicker
              id="end-datepicker"
              v-model="endDate"
              class="mb-1"
            />
            <p>Value: '{{ endDate }}'</p>
          </div>
        </b-col>
        <!-- <b-col>
          <div>
            <date-range-picker
              :startDate="startDate"
              :endDate="endDate"
              @update="console.log(value)"
              :locale-date="locale">
            </date-range-picker>
          </div>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col>
          <b-button
            varient="primary"
            class="btn-icon mr-1 mt-1"
            @click="printReportSummary(value, endDate)"
          >
            View Report
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <br>
          <p>
            Total Before Discount
          </p>
        </b-col>
        <b-col>
          <br>
          <p>
            {{ FormatNumber(RptBfDC) }}
          </p>
          <!-- <VueNumberFormat
            :options="{ precision: 2, prefix: '', suffix: '', decimal: '.', thousand: ',', acceptNegative: false, isInteger: true}"
          >{{ RptBfDC }}</VueNumberFormat> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            Total Discount
          </p>
        </b-col>
        <b-col>
          <p>
            {{ FormatNumber(RptDC) }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            Total Services Charge
          </p>
        </b-col>
        <b-col>
          <p>
            {{ FormatNumber(RptSC) }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            Total Vat
          </p>
        </b-col>
        <b-col>
          <p>
            {{ FormatNumber(RptVat) }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            Total Amount
          </p>
        </b-col>
        <b-col>
          <p>
            {{ FormatNumber(RptTotal) }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-ReportOrderSum
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
          >
            <p class="mt-1">
              Order Summary
            </p>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-ReportItemSum
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
          >
            <p class="mt-1">
              Order Detail
            </p>
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-ReportVoucher
            variant="outline-secondary"
            class="btn-icon mr-1 mt-1"
          >
            <p class="mt-1">
              Voucher Summary
            </p>
          </b-button> -->
        </b-col>
      </b-row>
    </b-card>
    <!-- modal vertical center -->
    <b-modal
      id="modal-ReportOrderSum"
      centered
      title="ReportOrderSum"
      ok-only
      ok-title="พิมพ์"
      @ok="callPrint('printReportOrderSum',value)"
    >
      <div>
        <label for="example-datepicker">Choose a date</label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="value"
          class="mb-1"
        />
        <p>Value: '{{ value }}'</p>
      </div>
    </b-modal>

    <!-- modal vertical center -->
    <b-modal
      id="modal-ReportItemSum"
      centered
      title="ReportItemSum"
      ok-only
      ok-title="พิมพ์"
      @ok="callPrint('printReportItemSum',value)"
    >
      <div>
        <label for="example-datepicker">Choose a date</label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="value"
          class="mb-1"
        />
        <p>Value: '{{ value }}'</p>
      </div>
    </b-modal>

    <b-modal
      id="modal-ReportVoucher"
      centered
      title="Voucher"
      ok-only
      ok-title="พิมพ์"
      @ok="callPrint('printReportVoucher',value)"
    >
      <div>
        <label for="example-datepicker">Choose a date</label>
        <b-form-datepicker
          id="example-datepicker"
          v-model="value"
          class="mb-1"
        />
        <p>Value: '{{ value }}'</p>
      </div>
    </b-modal>
  </div>
</template>

<script>

import {
  BButton, BCol, BRow, BCard,
  BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUserData } from '@/auth/utils'
import orderingStoreModule from './orderingStoreModule'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      printerName: 'Cashier',
      value: '',
      startDate: '2022-10-10',
      endDate: '',
      ReportTotal: 0.0,
      RptTotal: 0.0,
      RptSC: 0.0,
      RptVat: 0.0,
      RptBfDC: 0.0,
      RptDC: 0.0,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
  },
  setup() {
    const userData = getUserData()
    const OrderingStoreModuleName = 'ordering'
    // Register module
    if (!store.hasModule(OrderingStoreModuleName)) store.registerModule(OrderingStoreModuleName, orderingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(OrderingStoreModuleName)) store.unregisterModule(OrderingStoreModuleName)
    })

    return { userData }
  },
  // callReport2(StartDate, EndDate) {
  //   store.dispatch('ordering/getOrderReportSummary', { id: this.userData.branchId, StartDate, EndDate })
  //     .then(response => {
  //       this.ReportTotal = response[0].total_order_amount
  //     })
  //     .catch(error => null)
  // },

  methods: {

    FormatNumber(number) {
      const str_num = number.toString().split('.')
      if (str_num.length === 1) {
        return str_num[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
      }
      const fp = parseFloat(`0.${str_num[1]}`)
      const fpt = fp.toFixed(2).split('.')[1]
      return `${str_num[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}.${fpt}`
    },

    printReportSummary(StartDate, EndDate) {
      let dataS = {}

      console.log('checkStartDate', StartDate)
      console.log('checkEndDate', EndDate)

      dataS = store.dispatch('ordering/getOrderReportSummary', { id: this.userData.branchId, startDate: this.value, endDate: this.endDate })
        .then(res => {
          console.log('printReportSummary', res.data[0])
          console.log(JSON.stringify(res.data))

          const data2 = res.data[0]

          if (res.data[0] == null) {
            this.ReportTotal = 0
            this.RptTotal = 0
            this.RptSC = 0
            this.RptVat = 0
            this.RptBfDC = 0
            this.RptDC = 0
          } else {
            this.ReportTotal = res.data[0].total_order_amount
            this.RptTotal = res.data[0].total_order_amount
            this.RptSC = res.data[0].total_sc
            this.RptVat = res.data[0].total_vat
            this.RptBfDC = res.data[0].total_before_dc
            this.RptDC = res.data[0].total_dc
          }

          // this.ReportTotal = res.data[0].total_order_amount
          // this.RptTotal = res.data[0].total_order_amount
          // this.RptSC = res.data[0].total_sc
          // this.RptVat = res.data[0].total_vat
          // this.RptBfDC = res.data[0].total_before_dc
          // this.RptDC = res.data[0].total_dc

          // console.log('data2', data2)
          // console.log('order_detail_list', data2.order_detail_list)

          return data2
        })
        .catch(error => null)
      // const details = data.report_order_dtl.map(obj => ({
      //   customers: obj.customer !== null ? obj.customer : '-',
      //   payment: obj.payment !== null ? obj.payment : '-',
      //   tables: obj.table !== null ? obj.table : '-',
      //   total: obj.total !== null ? obj.total : 0,
      // }))

      // const obj = {
      //   printerName: this.printerName,
      //   totalBfDC: data.total_before_dc,
      //   totalDC: data.total_dc,
      //   subTotal: data.sub_total,
      //   sC10Per: data.total_sc,
      //   vat7Per: data.total_vat,
      //   grandTotal: data.total_order_amount,
      //   branch_app_ip: data.branch_app_ip,
      // }

      // console.log('obj', obj)

      // store.dispatch('ordering/printOrderSummary', obj)
      //   .then(response => {
      //     console.log('response', response)
      //   })
      //   .catch(error => {
      //     console.log('error', error)
      //   })
    },

    async printReportOrderSum(orderdate) {
      let data = {}

      console.log('printReportOrderSum')

      data = await store.dispatch('ordering/getOrderReportOrderSum', { id: this.userData.branchId, orderdate })
        .then(res => {
          console.log('printReportOrderSum', res.data[0])
          console.log(JSON.stringify(res.data))

          const data2 = res.data[0]

          // console.log('data2', data2)
          // console.log('order_detail_list', data2.order_detail_list)

          return data2
        })
        .catch(error => null)

      const details = data.report_order_dtl.map(obj => ({
        customers: obj.customer !== null ? obj.customer : '-',
        payment: obj.payment !== null ? obj.payment : '-',
        tables: obj.table !== null ? obj.table : '-',
        total: obj.total !== null ? obj.total : 0,
      }))

      const obj = {
        printerName: this.printerName,
        detail: details,
        totalBfDC: data.total_before_dc,
        totalDC: data.total_dc,
        subTotal: data.sub_total,
        sC10Per: data.total_sc,
        vat7Per: data.total_vat,
        grandTotal: data.total_order_amount,
        branch_app_ip: data.branch_app_ip,
      }

      console.log('obj', obj)

      store.dispatch('ordering/printOrderSum', obj)
        .then(response => {
          console.log('response', response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    async printReportVoucher(orderdate) {
      let data = {}

      console.log('printReportVoucher')

      data = await store.dispatch('ordering/getReportVoucher', { id: this.userData.branchId, orderdate })
        .then(res => {
          console.log('printReportVoucher', res.data[0])
          console.log(JSON.stringify(res.data))

          const data2 = res.data[0]

          // console.log('data2', data2)
          // console.log('order_detail_list', data2.order_detail_list)

          return data2
        })
        .catch(error => null)

      const details = data.report_order_dtl.map(obj => ({
        customers: obj.customer !== null ? obj.customer : '-',
        payment: obj.payment !== null ? obj.payment : '-',
        tables: obj.table !== null ? obj.table : '-',
        total: obj.total !== null ? obj.total : 0,
      }))

      const obj = {
        printerName: this.printerName,
        detail: details,
        totalBfDC: data.total_before_dc,
        totalDC: data.total_dc,
        subTotal: data.sub_total,
        sC10Per: data.total_sc,
        vat7Per: data.total_vat,
        grandTotal: data.total_order_amount,
        branch_app_ip: data.branch_app_ip,
      }

      console.log('Voucher', obj)

      store.dispatch('ordering/printReportVoucher', obj)
        .then(response => {
          console.log('response', response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },

    async printReportItemSum(orderdate) {
      let data = {}

      console.log('printReportOrderSum')

      data = await store.dispatch('ordering/getReportItemSum', { id: this.userData.branchId, orderdate })
        .then(res => {
          console.log('printReportOrderSum', res.data)
          console.log(JSON.stringify(res.data))

          const data2 = res.data

          // console.log('data2', data2)
          // console.log('order_detail_list', data2.order_detail_list)

          return data2
        })
        .catch(error => null)

      // const details = data.report_item_dtl.map(obj => ({
      //   itemService: obj.item !== null ? obj.item : 0,
      //   qty: obj.quantity !== null ? obj.quantity : 0,
      //   amt: obj.total !== null ? obj.total : 0,
      // }))

      const obj = {
        printerName: this.printerName,
        branch_app_ip: data[0].branch_app_ip,
        head: data,
      }

      console.log('obj', obj)

      store.dispatch('ordering/printItemSum', obj)
        .then(response => {
          console.log('response', response)
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    callPrint(check, orderdate) {
      if (check == 'printReportOrderSum') {
        this.printReportOrderSum(orderdate)
      } else if (check == 'printReportItemSum') {
        this.printReportItemSum(orderdate)
      }
    },
    callReprot(value, endDate) {
      this.printReportSummary(value, endDate)
    },
  },
}
</script>
